// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\codell\\Desktop\\tourettes.org\\web\\gatsby-site\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jim-js": () => import("C:\\Users\\codell\\Desktop\\tourettes.org\\web\\gatsby-site\\src\\pages\\about-jim.js" /* webpackChunkName: "component---src-pages-about-jim-js" */),
  "component---src-pages-contact-us-js": () => import("C:\\Users\\codell\\Desktop\\tourettes.org\\web\\gatsby-site\\src\\pages\\contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-get-connected-js": () => import("C:\\Users\\codell\\Desktop\\tourettes.org\\web\\gatsby-site\\src\\pages\\get-connected.js" /* webpackChunkName: "component---src-pages-get-connected-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\codell\\Desktop\\tourettes.org\\web\\gatsby-site\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-questions-about-tourettes-js": () => import("C:\\Users\\codell\\Desktop\\tourettes.org\\web\\gatsby-site\\src\\pages\\questions-about-tourettes.js" /* webpackChunkName: "component---src-pages-questions-about-tourettes-js" */),
  "component---src-pages-resources-js": () => import("C:\\Users\\codell\\Desktop\\tourettes.org\\web\\gatsby-site\\src\\pages\\resources.js" /* webpackChunkName: "component---src-pages-resources-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\codell\\Desktop\\tourettes.org\\web\\gatsby-site\\.cache\\data.json")

